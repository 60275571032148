import '../css/cart.css';

const $ = require('jquery');

function addProductAPI(productId, quantity) {
    $.ajax({
        data: {product_id: productId, quantity: quantity},
        url: addProductAPIUrl
    }).done(function (result) {
        // console.log(result);
    });
}

function removeProductAPI(productId) {
    $.ajax({
        data: {product_id: productId},
        url: removeProductAPIUrl
    }).done(function (result) {
        // console.log(result);
    });
}

$(document).ready(function ($) {
    const cartWrapper = $('.cd-cart-container');

    $(document).on('click', '.checkout', function (event) {
        event.preventDefault();
        //submit form cart_form
        console.log($('.cart-option').val());
        // if ($('.cart-option').val() === "") {
        //     alert('¿Vienes o vamos a llevártelo a casa?');
        // } else {
        //     $('#cart_form').submit();
        // }
    });

    if (cartWrapper.length > 0) {
        //store jQuery objects
        var cartBody = cartWrapper.find('.body')
        var cartList = cartBody.find('ul').eq(0);
        var cartTotal = cartWrapper.find('.checkout').find('span');
        const cartTrigger = cartWrapper.children('.cd-cart-trigger');
        var cartCount = cartTrigger.children('.count')
        const addToCartBtn = $('.cd-add-to-cart');
        var undo = cartWrapper.find('.undo');
        var undoTimeoutId;

        $(document).on('click', '.add-related', function (event) {
            let related = JSON.parse(decodeURIComponent($(event.target).data('related')));
            console.log(related);
            addToCart(related.id, related.name, related.price, related.image);
            $.magnificPopup.close();
        });
        $(document).on('click', '.close-modal', function () {
            $.magnificPopup.close();
        });

        //add product to cart
        addToCartBtn.on('click', function (event) {
            event.preventDefault();
            let productId = $(this).data('id');
            let name = $(this).data('name');
            let price = $(this).data('price');
            let image = $(this).data('image');
            addToCart(productId, name, price, image);
            if ($(this).data('related-id')) {
                let related = {
                    id: $(this).data('related-id'),
                    name: $(this).data('related-name'),
                    price: $(this).data('related-price'),
                    image: $(this).data('related-image')
                };
                let dataStr = encodeURIComponent(JSON.stringify(related));
                $.magnificPopup.open({
                    items: {
                        src: '<div class="white-popup">' +
                            '¿Quieres añadir ' + related.name + ' por ' + related.price + '€?<br>' +
                            '<div class="row">' +
                            '<div class="col-6">' +
                            '<a class="btn btn-primary add-related" data-related="' + dataStr + '">Si</a></div>' +
                            '<div class="col-6"><a class="btn btn-warning close-modal">No</a></div>' +
                            '</div></div>',
                    },
                    type: 'inline',
                    modal: true
                });
            }
        });

        //open/close cart
        cartTrigger.on('click', function (event) {
            event.preventDefault();
            toggleCart();
        });

        //close cart when clicking on the .cd-cart-container::before (bg layer)
        cartWrapper.on('click', function (event) {
            if ($(event.target).is($(this))) toggleCart(true);
        });

        //delete an item from the cart
        cartList.on('click', '.delete-item', function (event) {
            event.preventDefault();
            removeProduct($(event.target).parents('.cart-product'));
        });

        //update item quantity
        cartList.on('change', 'select', function (event) {
            let newQty = $(event.target).val();
            console.log('new', newQty);
            let lastQty = $(event.target).data('before');
            console.log('last', lastQty);
            let updQty = newQty - lastQty;
            console.log('upd', updQty);
            addProductAPI(
                $(event.target).data('id'),
                updQty
            );
            $(event.target).data('before', newQty);
            quickUpdateCart();
        });

        //reinsert item deleted from the cart
        undo.on('click', 'a', function (event) {
            clearInterval(undoTimeoutId);
            event.preventDefault();
            cartList.find('.deleted')
                .addClass('undo-deleted')
                .one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function () {
                    $(this).off('webkitAnimationEnd oanimationend msAnimationEnd animationend').removeClass('deleted undo-deleted').removeAttr('style');
                    quickUpdateCart();
                });
            const productId = cartList.find('.deleted').find('.quantity').find('select').data('id'),
                productQuantity = Number(cartList.find('.deleted').find('.quantity').find('select').val());

            addProductAPI(productId, productQuantity);

            undo.removeClass('visible');
        });
    }

    function toggleCart(bool) {
        const cartIsOpen = (typeof bool === 'undefined') ? cartWrapper.hasClass('cart-open') : bool;

        if (cartIsOpen) {
            cartWrapper.removeClass('cart-open');
            //reset undo
            clearInterval(undoTimeoutId);
            undo.removeClass('visible');
            cartList.find('.deleted').remove();

            setTimeout(function () {
                cartBody.scrollTop(0);
                //check if cart empty to hide it
                if (Number(cartCount.find('li').eq(0).text()) === 0) cartWrapper.addClass('empty');
            }, 500);
        } else {
            cartWrapper.addClass('cart-open');
        }
    }

    function addToCart(productId, name, price, image) {
        let $cdProduct = $('#cd-product-' + productId);

        if ($cdProduct.length) {
            $cdProduct.val(Number($cdProduct.val()) + 1);
            $cdProduct.trigger('change');
        } else {
            addProductAPI(productId, 1);
            //update total price
            const cartIsEmpty = cartWrapper.hasClass('empty');
            //update cart product list
            addProduct(productId, name, price, image);
            //update number of items
            updateCartCount(cartIsEmpty);
            updateCartTotal(price, true);
            //show cart
            cartWrapper.removeClass('empty');
        }
    }

    function addProduct(productId, name, price, image) {
        //this is just a product placeholder
        //you should insert an item with the selected product info
        //replace productId, productName, price and url with your real product info
        const productAdded = $('<li class="cart-product">' +
            '<div class="product-image">' +
            '<a href="#0">' +
            '<img class="cd-cart-product-image" src="' + image + '" alt="placeholder">' +
            '</a>' +
            '</div>' +
            '<div class="product-details">' +
            '<h3>' +
            '<a href="#0">' + name + '</a>' +
            '</h3>' +
            '<span class="cart-price">' + price + '€</span>' +
            '<div class="actions"><a href="#0" class="delete-item">Quitar</a>' +
            '<div class="quantity">' +
            '<label for="cd-product-' + productId + '">Cantidad</label>' +
            '<span class="select">' +
            '<select id="cd-product-' + productId + '" name="quantity" data-id="' + productId + '" data-before="1">' +
            '<option value="1">1</option>' +
            '<option value="2">2</option>' +
            '<option value="3">3</option>' +
            '<option value="4">4</option>' +
            '<option value="5">5</option>' +
            '<option value="6">6</option>' +
            '<option value="7">7</option>' +
            '<option value="8">8</option>' +
            '<option value="9">9</option>' +
            '</select></span></div></div></div></li>');
        cartList.prepend(productAdded);
    }

    function removeProduct(product) {
        clearInterval(undoTimeoutId);
        cartList.find('.deleted').remove();

        const topPosition = product.offset().top - cartBody.children('ul').offset().top,
            productId = product.find('.quantity').find('select').data('id'),
            productQuantity = Number(product.find('.quantity').find('select').val()),
            productTotPrice = Number(product.find('.cart-price').text().replace('€', '')) * productQuantity;

        removeProductAPI(productId);

        product.css('top', topPosition + 'px').addClass('deleted');

        //update items count + total price
        updateCartTotal(productTotPrice, false);
        updateCartCount(true, -productQuantity);
        undo.addClass('visible');

        //wait 8sec before completely remove the item
        undoTimeoutId = setTimeout(function () {
            undo.removeClass('visible');
            cartList.find('.deleted').remove();
        }, 8000);
    }

    function quickUpdateCart() {
        let quantity = 0;
        let price = 0;

        cartList.children('li:not(.deleted)').each(function () {
            const singleQuantity = Number($(this).find('select').val());
            quantity = quantity + singleQuantity;
            console.log('id ', $(this).find('.quantity').find('select').data('id'));
            console.log('price: ', $(this).find('.cart-price').text().replace('€', ''));
            price = price + singleQuantity * Number($(this).find('.cart-price').text().replace('€', ''));
        });

        cartTotal.text(price.toFixed(2));
        cartCount.find('li').eq(0).text(quantity);
        cartCount.find('li').eq(1).text(quantity + 1);
    }

    function updateCartCount(emptyCart, quantity) {
        let actual, next;
        if (typeof quantity === 'undefined') {
            actual = Number(cartCount.find('li').eq(0).text()) + 1;
            next = actual + 1;

            if (emptyCart) {
                cartCount.find('li').eq(0).text(actual);
                cartCount.find('li').eq(1).text(next);
            } else {
                cartCount.addClass('update-count');

                setTimeout(function () {
                    cartCount.find('li').eq(0).text(actual);
                }, 150);

                setTimeout(function () {
                    cartCount.removeClass('update-count');
                }, 200);

                setTimeout(function () {
                    cartCount.find('li').eq(1).text(next);
                }, 230);
            }
        } else {
            actual = Number(cartCount.find('li').eq(0).text()) + quantity;
            next = actual + 1;

            cartCount.find('li').eq(0).text(actual);
            cartCount.find('li').eq(1).text(next);
        }
    }

    function updateCartTotal(price, bool) {
        bool ? cartTotal.text((Number(cartTotal.text()) + Number(price)).toFixed(2)) : cartTotal.text((Number(cartTotal.text()) - Number(price)).toFixed(2));
    }
});
